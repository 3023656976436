import { getFirAuth } from '../firebase'
import { GAMIFCATION_ENDPOINTS } from '../utils/endpoints'
import { QueryParams, makeAPIRequest } from '../utils/request'
import { GamificationEngagementType } from './gamification.types'

/**
 * Send Gamification Achievement API
 */
export const sendGamificationEngagement = async (
  userId: string,
  event: GamificationEngagementType | string,
  engagementId: string | number
) => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) return null
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = {
    userId: userId,
    timestamp: new Date().getTime(),
    source: 'web',
    type: 'participation',
    event: event,
    engagementSourceId: engagementId,
  }
  try {
    const response = await makeAPIRequest(GAMIFCATION_ENDPOINTS.ENGAGEMENT, {
      method: 'POST',
      apiVersion: '1',
      headerParams,
      body: JSON.stringify(body),
    })

    if (response?.status && response?.status < 400) {
      return true
    }
    return false
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}
