import type { PlayerWithStatsData } from '../../Api'
export type PlayerSortKeys = keyof PlayerWithStatsData


export const statTitles: PlayerSortKeys[] = [
  'points',
  'onePointGoals',
  'twoPointGoals',
  'assists',
  'shotPct',
  'faceoffPct',
  'savePct',
  'groundBalls',
  'causedTurnovers',
]

export const defaultPlayerFavsPrimary: string[] = [
  'grant-ament',
  'matt-rambo',
  'lyle-thompson',
  'trevor-baptiste',
  'mikie-schlosser',
  'jeff-teat'
]

export const defaultPlayerFavsSecondary: string[] = [
  'myles-jones',
  'paul-rabil',
  'blaze-riorden',
  'tom-schrieber',
  'td-ierlan',
  'bryan-costabile'
]

export const defaultPlayerFavsThree: string[] = [
  'rob-pannel',
  'will-manny',
  'zed-williams',
  'josh-byrne',
  'colin-heacock',
  'marcus-holman'
]
